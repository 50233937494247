@import url('https://fonts.googleapis.com/css2?family=Jomolhari');
@import url('https://fonts.googleapis.com/css2?family=Noto');

.nw-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
}

.top-text{
    font-size: 300%;
    font-family: Inder;
    background-image: linear-gradient(to right bottom , rgb(0, 0, 0), rgb(178, 178, 178));
    text-transform: uppercase;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center; 
}

.middle{
    margin-top: 50px;
    overflow: hidden;
    background-color: white;
    white-space: nowrap;
}

.logo-slide{
    display: inline-block;
    animation: slide 20s infinite linear;
}

.network_logo{
    height: 100px;
    width: auto;
    padding-left: 50px;
    padding-right: 50px; 
    cursor: pointer;
}

.nw-side-head{
    font-size: 150%;
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    column-gap: 10px;
    margin-top: 30px;
}

.nw-side-head-text{
    font-family: Inder;
}

.sh-line{
    width: 5vw;
    border: none;
    background-image: linear-gradient(to right, rgb(0, 110, 255), violet);
    height: 2px;
    border-radius: 10px;
}

@keyframes slide {
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}

.advb-content{
    margin: auto;
    text-align: center;
    font-family: Inder;
    width: 80%;
    margin-top: 30px;
}

.advb-cont{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-top: 10vh;
    row-gap: 5vh;
}

.adv-pics{
    width: 75vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 30px;
    margin-top: 20px;
}

.top-adv{
    justify-content: space-between;
}

.bottom-adv{
    justify-content: space-evenly;
}

.adv-photo{
    width: 10vw;
    height: 10vw;
    border-radius: 100%;
}

.advisor{
    max-width: 17vw;
}

.adv-name{
    font-family: Inder;
    font-size: 120%;
    color: #5382FF;
    display: flex;
    column-gap: 20px;
    align-items: center;
    text-transform: capitalize;
}

.lin-icon{
    color: rgb(36, 36, 36);
    cursor: pointer;
}

.lin-icon:hover{
    color: #5382FF;
}

.adv-post{
    font-family: Inder;
}

.company{
    color: rgb(111, 111, 111);
}

.adv-logs{
    width: 75vw;
    padding-right: 5vw;
    padding-left: 30px;
    height: 8vh;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: white;
    box-shadow: 2px 2px 10px rgb(83, 130, 255, 0.5);
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adv-logo{
    max-width: 12vw;
}

.advb-card{
    width: 550px;
    box-shadow: 2px 2px 5px grey;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 3vw;
}

.advb-card:hover{
    transform: scale(1.02);
    transition: 0.5s;
}

.advb-card-right{
    width: 550px;
    box-shadow: 2px 2px 5px grey;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 3vw;
    text-align: right !important;
}

.advb-card-right:hover{
    transform: scale(1.02);
    transition: 0.5s;
}

.advb-card-right .adv-name{
    justify-content: flex-end;
}

.adv-details{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

/* ---------------------------- */


.im-heading{
    font-family: Inder;
    font-size: 300%;
    text-transform: uppercase;
    margin: auto;
    text-align: center;
    background-image: linear-gradient(to right bottom , black , rgba(0, 0, 0, 0.558));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.im-cards{
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 30px;
    justify-content: space-evenly;
    align-items: center;
    row-gap: 30px;
}

.im-card{
    width: 200px;
    height: 200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to bottom right, rgba(83, 129, 255, 0.7), rgb(13, 104, 200, 0.7));
    box-shadow: 2px 2px 5px grey;
    border-radius: 20px;
}

.im-img{
    width:120px;
    height:120px;
    background-repeat: repeat;
    background-size: cover;
    border-radius: 100%;
}

.im-name{
    font-family: Inder;
    font-size: 110%;
    text-align: center;
    text-transform: uppercase;
    color: white;
}

.im-role{
    font-family: Inder;
    font-size: 80%;
    text-align: center;
}