.gallery-container{
    margin-top: 40px;
    margin-bottom: 40px;
}

.gallery-content{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    overflow: auto;
    column-gap: 30px;
}

.galimg{
    max-width: 30vw;
}